import { useRef } from 'react';
import { useAuthContext } from '../contexts/AuthContext';

const useStudentLogin = () => {
  const {
    isLoading,
    login,
    loginError,
    isLoggedIn,
    resetLoginError,
    user,
  } = useAuthContext();

  const formRef = useRef(null);

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    let email = formRef.current.email.value;
    let password = formRef.current.password.value;

    login(email, password);
  };

  return {
    formRef,
    onLoginSubmit,
    onLoginChange: resetLoginError,
    loginError,
    loggedInSuccess: isLoggedIn,
    loginLoading: isLoading,
    loggedInUser: user?.learner || null,
  };
};

export default useStudentLogin;
