import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { convertQueryStringToJson } from '../utility/stringHelper';

export const COUPON_URI_KEY = 'coupon';
export const COUPON_SESSION_KEY = 'couponQueryParam';
export const CODE_URI_KEY = 'code';

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState({});

  const location = useLocation();

  useEffect(() => {
    const queryStr = location?.search || '';
    const queryParamsJson = convertQueryStringToJson(queryStr);

    setQueryParams(queryParamsJson);
  }, [location]);

  // Set coupon in Session Storage if in query param
  useEffect(() => {
    const coupon =
      queryParams[COUPON_URI_KEY] || queryParams[CODE_URI_KEY] || '';
    if (coupon) {
      sessionStorage.setItem(COUPON_SESSION_KEY, coupon);
    }
  }, [queryParams]);

  return {
    queryParams,
  };
};

export default useQueryParams;
