import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  CHECKOUT_TYPE_COURSE,
  CHECKOUT_TYPE_BUNDLE,
  ACTION_CHECKOUT_BUNDLE,
} from '../../contexts/UnifiedCheckoutContext/constants';
import { isCheckoutTypeValid } from '../../contexts/UnifiedCheckoutContext/helper';
import { bundlePurchaseSignup } from '../../services/courseBundleService';
import { initiateCheckoutWithEmail } from '../../services/signUpService';
import { trackInitiateCheckout, trackLead } from '../../utility/analytics';
import { getTimezoneId } from '../../utility/dateHelper';
import {
  getCourseSignupRoute,
  getBundleCheckoutRoute,
} from '../../utility/stringHelper';

export const SIGN_UP_EMAIL_SESSION_KEY = 'signupEmail';
export const SIGN_UP_ID_SESSION_KEY = 'signupId';
export const SIGN_UP_ACCESS_TOKEN_SESSION_KEY = 'accessToken';
export const SIGN_UP_USER_CREDITS_SESSION_KEY = 'signupUserCredits';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required('Email is required'),
});

const useInitCheckoutWithEmail = () => {
  const [signupEmail, setSignupEmail] = useState('');
  const [signupWithEmailErr, setSignupWithEmailErr] = useState('');
  const [signupWithEmailSuccess, setSignupWithEmailSuccess] = useState(false);
  const [isSigningUpWithEmail, setIsSigningUpWithEmail] = useState(false);

  const history = useHistory();

  const validateEmail = async (email) => {
    try {
      const isValid = await validationSchema.validate({ email });
      return isValid;
    } catch (e) {
      setSignupWithEmailErr('Invalid Email');
      return false;
    }
  };

  /**
   * Call initiate checkout with email API, and save value of email and signup id in session to be reterived from checkout page.
   * On success from initiate checkout with email API, redirect to checkout page.
   * Use checkoutType to identify if checkout is for course purchase of bundle purchase and call relevant API based on that.
   *
   * @param {*} email string
   * @param {*} checkoutType string | CHECKOUT_TYPE_COURSE || CHECKOUT_TYPE_BUNDLE
   * @param {*} courseOfferingId string
   * @param {*} bundleData object
   * @param {*} bundleData.bundleId string
   * @param {*} bundleData.bundleSlug string
   * @param {*} bundleData.bundleCourses [string]
   * @param {*} bundleData.isGiftPurchase boolean
   */
  const initCheckoutWithEmail = async ({
    email,
    checkoutType,
    courseOfferingId,
    bundleData,
    bundleCourses,
    autoCreateBundle,
  }) => {
    if (!isCheckoutTypeValid(checkoutType)) return;

    setIsSigningUpWithEmail(true);
    setSignupWithEmailErr('');

    const isValid = await validateEmail(email);
    if (!isValid) {
      setIsSigningUpWithEmail(false);
      return;
    }

    // initialize the following data to be retereived from API.
    let bookingId = '';
    let accessToken = '';
    let creditAmtCents = '';

    if (checkoutType === CHECKOUT_TYPE_COURSE) {
      // Sign up Id return from sign up email api call
      const payload = {
        timezone: getTimezoneId(),
        courseCode: courseOfferingId,
        courseType: 'general',
        basicInfo: [{ studentInfo: { email } }],
      };

      const { data, error } = await initiateCheckoutWithEmail(payload);

      if (error) {
        setSignupWithEmailErr(error);
        setIsSigningUpWithEmail(false);
        return false;
      }

      const { signup_id, access_token, credit_amount } = data;
      setIsSigningUpWithEmail(false);

      bookingId = signup_id;
      accessToken = access_token;
      creditAmtCents = credit_amount;
    }

    if (checkoutType === CHECKOUT_TYPE_BUNDLE) {
      const { bundleId, bundleCourses, isGiftPurchase } = bundleData;
      const payload = {
        bundleId,
        bundleCourses,
        agreeTermsAndCondition: true,
        timezone: getTimezoneId(),
        metadata: {
          purchasedBy: { email },
          isGiftPurchase,
        },
      };

      const { data, error } = await bundlePurchaseSignup(payload);
      if (error) {
        setSignupWithEmailErr(error);
        setIsSigningUpWithEmail(false);
        return false;
      }

      const { id, access_token } = data;
      setIsSigningUpWithEmail(false);

      bookingId = id;
      accessToken = access_token;
    }

    // Set values in session to retreive on checkout page.
    sessionStorage.setItem(SIGN_UP_EMAIL_SESSION_KEY, email);
    sessionStorage.setItem(SIGN_UP_ID_SESSION_KEY, bookingId);
    sessionStorage.setItem(SIGN_UP_ACCESS_TOKEN_SESSION_KEY, accessToken);
    sessionStorage.setItem(SIGN_UP_USER_CREDITS_SESSION_KEY, creditAmtCents);

    // track analytics
    trackLead(signupEmail);
    trackInitiateCheckout();

    // redirect to checkout
    let redirectRoute = '';

    if (checkoutType === CHECKOUT_TYPE_COURSE)
      redirectRoute = getCourseSignupRoute(courseOfferingId);

    if (checkoutType === CHECKOUT_TYPE_BUNDLE) {
      const { bundleSlug } = bundleData;
      redirectRoute = getBundleCheckoutRoute({
        bundleSlug,
        bundleAction: ACTION_CHECKOUT_BUNDLE,
        bundleCourses,
        autoCreateBundle,
      });
    }

    history.push(redirectRoute);
  };

  useEffect(() => {
    setSignupWithEmailErr('');
  }, [signupEmail]);

  return {
    signupEmail,
    setSignupEmail,
    signupWithEmailErr,
    setSignupWithEmailErr,
    signupWithEmailSuccess,
    setSignupWithEmailSuccess,
    isSigningUpWithEmail,
    setIsSigningUpWithEmail,

    initCheckoutWithEmail,
  };
};

export default useInitCheckoutWithEmail;
