import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  userLoginService,
  getUserProfile,
  getTokensService,
} from '../../services/authService';
import Auth from '../../modules/auth';

export const AuthContext = createContext();

const initialAccessToken = Auth.getUserToken() || '';
const initialRefreshToken = Auth.getRefreshToken() || '';

export const AuthContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [socialLoginError, setSocialLoginError] = useState('');
  const [user, setUser] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  // Standard email, password Login
  const login = async (email, password) => {
    setIsLoading(true);
    const res = await userLoginService(email, password);

    if (res.error) {
      setLoginError(res.error);
      setIsLoading(false);
      return;
    }

    const { token, refresh_token } = res;

    setRefreshToken(refresh_token);
    setAccessToken(token);

    setIsLoading(false);
  };

  const handleSocialAuth = (response, provider) => {
    let authToken = '';
    switch (provider) {
      case 'facebook':
        const fbToken = response?.accessToken;
        authToken = fbToken;
        break;
      case 'google':
        const googleToken = response?.tokenObj?.id_token;
        authToken = googleToken;
        break;
      default:
    }

    if (authToken) getTokens({ authToken });
  };

  /**
   * Use token received from socal logins (authToken) or refresh token and pass to backend to get access token and refresh token
   * Payload should send either authToken or refreshToken
   * @param {*} tokenPayload | Object
   * @param {*} tokenPayload.authToken | String | For Social Logins
   * @param {*} tokenPayload.refreshToken | String
   */
  const getTokens = async (tokenPayload) => {
    setIsLoading(true);
    setSocialLoginError('');
    const { data, error } = await getTokensService(tokenPayload);
    setIsLoading(false);

    if (error) {
      setSocialLoginError(error || 'Error Logging in. Please try again.');
      return;
    }

    const { token, refresh_token } = data;
    if (!token || !refresh_token) return;

    setUser(null);
    setRefreshToken(refresh_token);
    setAccessToken(token);
  };

  const loginWithToken = (accessToken, refreshToken) => {
    Auth.authenticateUser(accessToken, refreshToken);
    getUserData();

    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
  };

  const getUserData = async () => {
    const { data, error } = await getUserProfile();
    if (error || !data) return;

    setUser(data);
  };

  const toggleLoginModal = () => setOpenLoginModal((open) => !open);

  // If Access Token & Refresh Token are updated, set it in localStorage AND get user data using the access token. If access token and refresh token are revoked, reset user to null
  useEffect(() => {
    if (accessToken && refreshToken) {
      if (!user) {
        Auth.authenticateUser(accessToken, refreshToken);
        getUserData();
      }
    }

    if (!accessToken && !refreshToken) {
      Auth.deauthenticateUser();
      setUser(null);
    }
  }, [accessToken, refreshToken, user]);

  useEffect(() => {
    if (!!user) setOpenLoginModal(false);
  }, [user]);

  const value = {
    login,
    loginWithToken,
    handleSocialAuth,
    logout,
    loginError,
    setLoginError,
    socialLoginError,
    resetLoginError: () => setLoginError(''),

    accessToken,
    refreshToken,
    isLoggedIn: !!user,
    user,
    isLoading,

    toggleLoginModal,
    openLoginModal,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
