import {
  PAYMENT_FAILURE,
  PAYMENT_PENDING,
  PAYMENT_SUCCESS,
} from '../../hooks/usePayment/helper';
import { COUPON_SESSION_KEY } from '../../hooks/useQueryParams';
import {
  SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
  SIGN_UP_EMAIL_SESSION_KEY,
  SIGN_UP_ID_SESSION_KEY,
  SIGN_UP_USER_CREDITS_SESSION_KEY,
} from '../../hooks/checkout/useStartCheckout';

import {
  CHECKOUT_TYPE_COURSE,
  CHECKOUT_TYPE_BUNDLE,
  ACTION_CREATE_BUNDLE,
  DISCOUNT_TYPE_PERCENT_OFF,
  DISCOUNT_TYPE_AMOUNT_OFF,
  GIFT_CODE_TYPE,
} from './constants';
import { convertJsonToQueryString } from '../../utility/stringHelper';

export const isCheckoutTypeValid = (checkoutType) =>
  [CHECKOUT_TYPE_COURSE, CHECKOUT_TYPE_BUNDLE].includes(checkoutType);

export const getCheckoutRoute = ({
  type,
  courseOfferingId,
  step = 'enroll',
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  bundleCourses,
  autoCreateBundle,
}) => {
  switch (type) {
    case CHECKOUT_TYPE_COURSE:
      if (!courseOfferingId) return '/';

      const queryObject = {
        type,
        courseOfferingId,
        step,
      };

      return `/checkout${convertJsonToQueryString(queryObject)}`;
    case CHECKOUT_TYPE_BUNDLE: {
      if (!bundleSlug) return '/';

      if (autoCreateBundle) {
        const queryObject = {
          type,
          bundleSlug,
          bundleAction: 'checkout',
          step,
          autoCreateBundle: 1,
          bundleCourses,
        };

        return `/checkout${convertJsonToQueryString(queryObject)}`;
      }

      const queryObject = {
        type,
        bundleSlug,
        bundleAction,
        step,
      };

      return `/checkout${convertJsonToQueryString(queryObject)}`;
    }
    default:
      return '/';
  }
};

export const setCheckoutSessionValues = () => {};

// Get data saved in session for checkout on previous steps
export const getCheckoutSessionValues = () => {
  const signupEmail = sessionStorage.getItem(SIGN_UP_EMAIL_SESSION_KEY);

  const signupId = sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY);

  // userCreditAmount should be in dollars and not cents
  const sessionCreditValueInCents = sessionStorage.getItem(
    SIGN_UP_USER_CREDITS_SESSION_KEY
  );
  // Divide by 100 to convert to dollars
  const userCreditAmount = sessionCreditValueInCents
    ? parseInt(sessionCreditValueInCents, 10) / 100
    : 0;

  // Autoapply this coupon if present in session
  const couponQueryParam = sessionStorage.getItem(COUPON_SESSION_KEY) || '';

  return {
    signupEmail,
    signupId,
    userCreditAmount,
    couponQueryParam,
  };
};

// Clear All Checkout releated session data on completion.
export const clearSessionData = () => {
  sessionStorage.removeItem(SIGN_UP_EMAIL_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_ID_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_USER_CREDITS_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_ACCESS_TOKEN_SESSION_KEY);
};

// Get slug by payment status for url
export const getPaymentSlugByStatus = (paymentStatus) => {
  if (paymentStatus === PAYMENT_SUCCESS) return 'payment-success';
  if (paymentStatus === PAYMENT_FAILURE) return 'payment-failure';
  if (paymentStatus === PAYMENT_PENDING) return 'payment-pending';
};

// Get Payment metadata
export const getPaymentInformation = (paymentOptions, totalUsers = 1) => {
  // idx is the index at which payments data will be available in paymentOptions.paymentList array for a given number of users i.e totalUsers
  const idx = totalUsers;
  const paymentOption = paymentOptions?.paymentList?.[idx] ?? null;
  const discountEnabled = paymentOption?.discountEnabled || false;
  const depositEnabled = paymentOption?.depositEnabled || false;
  const depositAmount = paymentOption?.depositAmount || 0;
  const depositAmountLocal = paymentOption?.depositAmountLocal || 0;
  const totalAmount = paymentOption?.totalAmount || 0;
  const fullAmount = paymentOption?.fullAmount || 0;

  return {
    paymentOption,
    discountEnabled,
    depositEnabled,
    depositAmount,
    depositAmountLocal,
    totalAmount,
    fullAmount,
  };
};

// Get discount value in USD
export const getDiscountCodeAmount = ({
  discountDetails,
  discountApplied,
  totalAmount,
}) => {
  if (!discountDetails || !discountApplied) return 0;

  const { type, value } = discountDetails;

  if (type === DISCOUNT_TYPE_PERCENT_OFF) {
    return totalAmount * (value / 100);
  } else if (type === DISCOUNT_TYPE_AMOUNT_OFF) {
    // discount value is returned in cents
    const discountValueInUsd = value / 100;
    return discountValueInUsd;
  } else {
    return 0;
  }
};

// Get message to display to the user if gift amount greater than purchase price.
export const computePostGiftCodeAppliedMessage = (
  discountApplied,
  discountDetails,
  totalAmount
) => {
  let msg = '';

  if (discountApplied && discountDetails) {
    const { codeType, type, value } = discountDetails;
    const discountAmount = value / 100; // convert cents to usd
    const priceDiff = discountAmount - totalAmount;
    const surplusGiftAmountCondition =
      codeType === GIFT_CODE_TYPE &&
      type === DISCOUNT_TYPE_AMOUNT_OFF &&
      priceDiff > 0; // means discountMount is greater than the toal amount

    if (surplusGiftAmountCondition) {
      msg = [
        { text: 'Congratulations! ', isBolder: true },
        { text: 'You can enroll to this course for ' },
        { text: 'FREE! ', isBolder: true },
        { text: 'The surplus amount of ' },
        { text: `USD ${priceDiff} `, isBolder: true },
        { text: 'from the Gift Code will be added to your credits.' },
      ];
    }
  }

  return msg;
};

export const getCalculatedFinalAmount = ({
  useUserCredit,
  userCreditAmount,
  discountApplied,
  discountCodeAmt,
  totalAmount,
  fullAmount,
}) => {
  if (useUserCredit || discountApplied) {
    const userCreditDiscount = useUserCredit ? userCreditAmount : 0;
    const calculatedAmountAfterDiscount =
      totalAmount - userCreditDiscount - discountCodeAmt;

    return calculatedAmountAfterDiscount < 0
      ? 0
      : calculatedAmountAfterDiscount;
  }

  return fullAmount;
};
