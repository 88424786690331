import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Aux from './hoc/Auxiliary';
import classes from './App.module.css';
import { scrollToTop } from './utility/domHelpers';
import useQueryParams from './hooks/useQueryParams';

import './App.scss';
import {
  COMEDY_SKITS_LOW_COST_COURSE_CODE,
  courseLinks,
} from './data/coursesData';
import MarkianRedirect from './pages/ComedySkitsWithMarkianPage/components/MarkianRedirect/MarkianRedirect';
import RedirectWithQuery from './components/RedirectWithQuery';
import LearningPortalIframe from './components/LearningPortalIframe/LearningPortalIframe';
import pageMetaMap from './utility/pageMetaMap';
import MetaHeader from './components/MetaHeader';
import useGeolocation from './hooks/useGeolocation';

// import contexts
const EnrollContextProvider = lazy(() => import('./contexts/EnrollContext'));
const CheckoutContextProvider = lazy(() =>
  import('./contexts/CheckoutContext')
);
const GiftCardContextProvider = lazy(() =>
  import('./contexts/GiftCardContext')
);
const GiftBundleContextProvider = lazy(() =>
  import('./contexts/GiftBundleContext')
);
const StripeWrapper = lazy(() => import('./components/StripeWrapper'));

// import pages
const OnDemandUnstoppableConfidence = lazy(() =>
  import('./pages/OnDemandUnstoppableConfidence')
);
const EnrolmentTerm = lazy(() =>
  import('./components/Policies/EnrolmentTermPage')
);
const PrivacyTerm = lazy(() =>
  import('./components/Policies/PrivacyPage/privacyTerm')
);
const CorporateEnquiryWrapper = lazy(() =>
  import(
    './pages/CorporateCoursePage/components/CorporateEnquiryWrapper/CorporateEnquiryWrapper'
  )
);
const ContentError = lazy(() => import('./components/ContentError'));
const UnsubscribePage = lazy(() =>
  import('./components/UI/Unsubscribe/Unsubscribe')
);
const TrainerPage = lazy(() => import('./components/TrainerPage/TrainerPage'));
const EnrollPage = lazy(() => import('./pages/EnrollPage/EnrollPageContainer'));

const CreatorCoursePageLightVariant = lazy(() =>
  import('./pages/CreatorCoursePage/variants/CreatorMasterCourseLightVariant')
);
const StoryTellingCoursePage = lazy(() =>
  import('./pages/StoryTellingCoursePage/StoryTellingCoursePageContainer')
);
const OnDemandFacebookCoursePage = lazy(() =>
  import('./pages/OnDemandFacebookCoursePage')
);
const SensitiveTopicCoursePage = lazy(() =>
  import('./pages/SensitiveTopicABContainer')
);
const OneDayCourseFullPage = lazy(() =>
  import('./pages/OneDayCourseFullPage/OneDayCourseFullPageContainer')
);
const DearAlyneOnDemandPage = lazy(() =>
  import('./pages/OnDemandDAContentCreationCoursePage')
);
const DearAlyneMoneyPage = lazy(() =>
  import('./pages/DearAlyneMoneyCourse')
)

const HomePage = lazy(() => import('./pages/HomePageABContainer'));
const ReferralLandingPage = lazy(() => import('./pages/ReferralLandingPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const GiftCardPage = lazy(() => import('./pages/GiftCardPage'));
const GiftBundlePage = lazy(() => import('./pages/GiftBundlePage'));
const CorporateCoursePage = lazy(() => import('./pages/CorporateCoursePage'));
const TrialClassPage = lazy(() => import('./pages/TrialClassPage'));
const InstructorListingPage = lazy(() =>
  import('./pages/InstructorListingPage')
);
const UnlockingTikTokWithMingPage = lazy(() =>
  import('./pages/UnlockingTikTokWithMingPageV2')
);
const OnDemandMetakovanCoursePage = lazy(() =>
  import('./pages/OnDemandMetakovanCoursePage')
);
const ComedySkitsWithMarkianPageContainer = lazy(() =>
  import('./pages/ComedySkitsWithMarkianPage')
);
const ComedySkitsWithMarkianDarkPageContainer = lazy(() =>
  import('./pages/ComedySkitsWithMarkianDarkPage')
);
const ComedySkitsLowPriceVariantContainer = lazy(() =>
  import('./pages/ComedySkitsLowPriceVariantPage')
);
const DearAlyneUniversity = lazy(() => import('./pages/DearAlyneUniversity'));
const NuseirUniversityPage = lazy(() => import('./pages/NuseirUniversityPage'));
const AdvancedCoursePage = lazy(() => import('./pages/AdvancedCoursePage'));

const EnrollmentPageV2 = lazy(() => import('./pages/EnrollmentPageV2'));
const PaymentReviewPage = lazy(() => import('./pages/PaymentReviewPage'));
const BundlePaymentReviewPage = lazy(() =>
  import('./pages/BundlePaymentReviewPage')
);
const RecommendEnginePage = lazy(() => import('./pages/RecommendEnginePage'));

const CheckoutContainer = lazy(() => import('./containers/CheckoutContainer'));
const AboutUsPage = lazy(() => import('./pages/AboutUsPage'));
const Campaign11Page = lazy(() => import('./pages/Campaign11Page'));

const MXPlayerPage = lazy(() => import('./pages/MXPlayerPage'));
const MetakovanPage = lazy(() => import('./pages/MetakovanPage'));

const MarkianCoursesPage = lazy(() => import('./pages/MarkianCoursesPage'));

const App = () => {
  const [mainContentClassName, setMainContentClassName] = useState(
    'page-container'
  );
  useQueryParams();
  useGeolocation();
  const location = useLocation();

  const className = 'c-App';
  const baseClassNames = [
    className,
    ' ', // add space at the end to concat legacy classes.App
  ].join('');

  const pathname = useMemo(() => location?.pathname || '', [location]);
  const metaData = pageMetaMap[pathname];

  useEffect(() => {
    scrollToTop();
  }, [location]);

  useEffect(() => {
    const giftCardRoute = '/gift-card';
    const route404 = '/not-found';
    const pathname = location?.pathname || '';
    const isGiftPageRoute =
      pathname === giftCardRoute || pathname.startsWith('/gift-bundle');
    const isRoute404 = pathname === route404;
    const isOrphanMasterCourse = pathname.startsWith('/course/creatormaster');
    const isCheckoutRoute = pathname.startsWith('/checkout');

    const isNavHidden =
      isCheckoutRoute || isGiftPageRoute || isRoute404 || isOrphanMasterCourse;

    if (isNavHidden) {
      setMainContentClassName('');
    } else {
      setMainContentClassName('page-container');
    }
  }, [location]);

  return (
    <Aux>
      <MetaHeader meta={metaData} />
      <main className={baseClassNames.concat(classes.App)}>
        <div className={mainContentClassName}>
          <Suspense
            fallback={
              <div style={{ background: '#2c2d2e', height: '100%' }}></div>
            }
          >
            <Switch>
              <Route
                exact
                path="/"
                render={() => <HomePage isSilentVideoVariant={true} />}
              />

              <Route path="/code/:referralCode" exact component={HomePage} />
              <Route
                exact
                path="/hidden-lp-iframe"
                component={LearningPortalIframe}
              />
              <Route
                exact
                path="/refer-a-friend"
                component={ReferralLandingPage}
              />
              <Route
                exact
                path="/courses/:level/:courseOfferingId/enrol-new"
                render={(props) => (
                  <EnrollContextProvider>
                    <StripeWrapper>
                      <EnrollPage {...props} />
                    </StripeWrapper>
                  </EnrollContextProvider>
                )}
              />
              <Route
                exact
                path="/course-signup/:stepSlug"
                render={(props) => (
                  <StripeWrapper>
                    <CheckoutContextProvider>
                      <EnrollmentPageV2 {...props} />
                    </CheckoutContextProvider>
                  </StripeWrapper>
                )}
              />
              <Route
                exact
                path="/payment-status/:status"
                component={PaymentReviewPage}
              />
              <Route
                exact
                path="/bundle-payment-status/:status"
                component={BundlePaymentReviewPage}
              />
              {/* <Route
                exact
                path={'/custom-bundle/:bundleSlug/:action'}
                component={CustomBundleContainer}
              /> */}
              <Route exact path={`/checkout`} component={CheckoutContainer} />
              {/* Needed for legacy link */}
              <Route
                path="/courses/NG"
                exact
                component={() => {
                  window.location.href = '/courses/beginner';
                  return null;
                }}
              />
              <Redirect from="/courses/creator" to="/course/creator" />
              <Redirect from="/courses" to="/course/creator" />
              {/* Creator Mastercourse Light Course Page */}
              <Route
                exact
                path="/course/creator"
                component={CreatorCoursePageLightVariant}
              />
              {/* Master Course Orphan Page */}
              <Route
                exact
                path="/course/creatormaster"
                render={(props) => (
                  <StripeWrapper>
                    <CreatorCoursePageLightVariant {...props} isOrphan />
                  </StripeWrapper>
                )}
              />
              {/* Story Telling Course Page Default Variant */}
              <Route
                exact
                path="/nasdaily"
                component={StoryTellingCoursePage}
              />
              <Redirect from="/course/storytelling" to="/nasdaily" />
              {/* ------------Do not remove this, we will need it later------------ */}
              {/* Story Telling Course Page with Journey Section
              <Route
                exact
                path="/course/storytelling-m"
                render={(props) => (
                  <StripeWrapper>
                    <StoryTellingCoursePage {...props} variantId={3} />
                  </StripeWrapper>
                )}
              />

              Story Telling Course Page with Opened FAQ
              <Route
                exact
                path="/course/storytelling-q"
                render={(props) => (
                  <StripeWrapper>
                    <StoryTellingCoursePage {...props} variantId={1} />
                  </StripeWrapper>
                )}
              />

              Story Telling Course Page with Value Props
              <Route
                exact
                path="/course/storytelling-v"
                render={(props) => (
                  <StripeWrapper>
                    <StoryTellingCoursePage {...props} variantId={2} />
                  </StripeWrapper>
                )}
              />

              Story Telling Course Page with all combined new variants
              <Route
                exact
                path="/course/storytelling-c"
                render={(props) => (
                  <StripeWrapper>
                    <StoryTellingCoursePage {...props} variantId={4} />
                  </StripeWrapper>
                )}
              /> */}
              {/* Advanced Course Page */}
              <Route
                exact
                path="/course/advanced"
                component={AdvancedCoursePage}
              />
              <Route
                exact
                path="/course/business"
                component={CorporateCoursePage}
              />
              <RedirectWithQuery
                from="/course/taboo-topics"
                to="/course/sensitive-topics"
              />
              <Route
                exact
                path="/course/sensitive-topics"
                render={(props) => (
                  <StripeWrapper>
                    <SensitiveTopicCoursePage {...props} />
                  </StripeWrapper>
                )}
              />
              <Route
                exact
                path="/course/facebook"
                render={(props) => (
                  <StripeWrapper>
                    <OnDemandFacebookCoursePage {...props} />
                  </StripeWrapper>
                )}
              />
              <Route
                exact
                path="/projectnightfall"
                render={(props) => (
                  <StripeWrapper>
                    <OnDemandUnstoppableConfidence {...props} />
                  </StripeWrapper>
                )}
              />
              <Redirect from="/course/confidence" to="/projectnightfall" />

              <Route path="/mxtakatak" exact component={MXPlayerPage} />
              <Route path="/metapurse" exact component={MetakovanPage} />
              <Route path ="/dearalyne/money" exact component={DearAlyneMoneyPage} />

              <Route
                exact
                path="/cryptocreator"
                render={(props) => (
                  <StripeWrapper>
                    <OnDemandMetakovanCoursePage {...props} />
                  </StripeWrapper>
                )}
              />

              {/* Start ---- Local Pricing AB Test */}

              <Route
                exact
                path="/cryptocreator-l"
                render={() => (
                  <OnDemandUnstoppableConfidence localPricing={true} />
                )}
              />

              {/* End ---- Local Pricing AB Test */}

              {/* One Day Course Full Page */}
              {/* To delete coming soon page */}
              <Route
                exact
                path="/course/:uriCourseName"
                render={(props) => (
                  <StripeWrapper>
                    <OneDayCourseFullPage {...props} />
                  </StripeWrapper>
                )}
              />
              <Route
                exact
                path="/dearalyne"
                render={(props) => <DearAlyneOnDemandPage {...props} />}
              />
              <Redirect from="/dear-alyne" to="/dearalyne" />
              <Route
                exact
                path="/ming"
                render={(props) => (
                  <StripeWrapper>
                    <UnlockingTikTokWithMingPage {...props} />
                  </StripeWrapper>
                )}
              />
              <Redirect
                from="/ming-wei/unlocking-tiktok-with-ming"
                to="/ming"
              />
              <Redirect from="/ming/tiktok" to="/unlocking-tiktok-with-ming" />

              <Route
                exact
                path="/smilesquad/advanced"
                component={MarkianCoursesPage}
              />

              <Route
                exact
                path="/smilesquad/comedy-skits-o"
                render={(props) => (
                  <StripeWrapper>
                    <ComedySkitsWithMarkianPageContainer {...props} />
                  </StripeWrapper>
                )}
              />
              <Route
                exact
                path="/smilesquad"
                render={(props) => (
                  <StripeWrapper>
                    <ComedySkitsWithMarkianDarkPageContainer
                      show30PercentSeats={true}
                      showPerksSection={true}
                    />
                  </StripeWrapper>
                )}
              />
              <Redirect from="/smilesquad/comedy-skits" to="/smilesquad" />
              <Route
                exact
                path={courseLinks[COMEDY_SKITS_LOW_COST_COURSE_CODE]}
                component={() => <ComedySkitsLowPriceVariantContainer />}
              />
              <Route
                exact
                path="/markian/comedy-skits"
                component={MarkianRedirect}
              />
              <Route exact path="/smilesquad" component={MarkianRedirect} />
              <Route
                exact
                path="/nuseir-university"
                component={NuseirUniversityPage}
              />
              <Route
                exact
                path="/da-university"
                component={DearAlyneUniversity}
              />
              <Route
                path="/course/business/enquire"
                component={CorporateEnquiryWrapper}
              />
              <Route exact path="/trial" component={TrialClassPage} />
              <Route
                exact
                path="/trial-n"
                render={() => <TrialClassPage variant="1" />}
              />
              {/* Needed for legacy link */}
              <Route
                path="/class/community-class"
                exact
                component={() => {
                  window.location.href = '/trial';
                  return null;
                }}
              />
              <Route exact path="/trainers" component={InstructorListingPage} />
              <Route path="/trainer/:trainerId" component={TrainerPage} />
              <Route exact path="/faq" component={FaqPage} />
              <Route path="/unsubscribe" exact component={UnsubscribePage} />
              <Route exact path="/privacy" component={PrivacyTerm} />
              <Route exact path="/enrolment-terms" component={EnrolmentTerm} />
              <Route
                exact
                path="/gift-card"
                component={() => (
                  <StripeWrapper>
                    <GiftCardContextProvider>
                      <GiftCardPage />
                    </GiftCardContextProvider>
                  </StripeWrapper>
                )}
              />
              <Redirect from="/bundle" to="/gift-bundle/six-in-one-bundle" />
              <Route
                exact
                path="/gift-bundle/:giftBundleSlug"
                component={(props) => (
                  <StripeWrapper>
                    <GiftBundleContextProvider {...props}>
                      <GiftBundlePage />
                    </GiftBundleContextProvider>
                  </StripeWrapper>
                )}
              />
              <Route
                path="/best-for-me"
                exact
                component={RecommendEnginePage}
              />
              <Route path="/impact" exact component={Campaign11Page} />
              <Route path="/about-us" exact component={AboutUsPage} />
              <Route path="/not-found" exact component={ContentError} />
              <Route
                path="*"
                component={(props) => {
                  return <ContentError {...props} />;
                }}
              />
            </Switch>
          </Suspense>
        </div>
      </main>
    </Aux>
  );
};

export default withRouter(App);
