const ACCESS_TOKEN_KEY = 'accessTokenNA';
const REFRESH_TOKEN_KEY = 'refreshTokenNA';

class Auth {
  /**
   * Save the user data in session Storage
   *
   * @param {string} userData
   * @param {string} accessToken
   */
  // static authenticateUser(userData, accessToken) {
  //   localStorage.setItem('userDataNA', JSON.stringify(userData));
  //   localStorage.setItem('accessTokenNA', accessToken);
  // }

  /**
   * Save the accessToken in local Storage
   *
   * @param {string} accessToken
   * @param {string} refreshToken
   */
  static authenticateUser(accessToken, refreshToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  /**
   * De-authenticate a user.
   * Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  /**
   * return user data
   *
   * @returns {object}
   */
  // static getUserData() {
  //   return JSON.parse(localStorage.getItem('userDataNA'));
  // }
}

export default Auth;
