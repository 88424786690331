import React, { useEffect } from 'react';
import Auth from '../../modules/auth';
import { useAuthContext } from '../../contexts/AuthContext';
import config from '../../utility/config';

const LearningPortalIframe = () => {
  const { loginWithToken } = useAuthContext();

  useEffect(() => {
    const handleMessage = (e) => {
      try {
        if (e.origin !== config.learningPortalPath) return;

        const localToken = Auth.getUserToken();
        const lpTokenId = e.data?.lpAccessToken || '';
        const lpRefreshTokenId = e.data?.lpRefreshToken || '';

        if (lpTokenId && lpTokenId !== localToken) {
          loginWithToken(lpTokenId, lpRefreshTokenId);
        }

        if (!lpTokenId) Auth.deauthenticateUser();
      } catch (e) {
        console.error('iframe handleMessage err ', e);
      }
    };

    try {
      document.domain = 'nas.academy';
      window.addEventListener('message', handleMessage, false);
    } catch (e) {
      console.error('err', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default LearningPortalIframe;
