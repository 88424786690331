import React, { createContext, useCallback, useContext, useState } from 'react';

const UserSessionContext = createContext();

export const UserSessionContextProvider = ({ children }) => {
  const [impactEmailPopupShown, setImpactEmailPopupShown] = useState(false);
  const [referralDiscountPopupShown, setReferralDiscountPopupShown] = useState(
    false
  );
  const [withValidReferralCode, setWithValidReferralCode] = useState(false);
  const [currentReferralCode, setCurrentReferralCode] = useState({
    owner: null,
    code: null,
  });
  const [sessionHomePageLogoLink, setSessionHomePageLogoLink] = useState('/');

  const turnOffImpactEmailPopup = useCallback(() => {
    setImpactEmailPopupShown(true);
  }, []);

  const turnOffReferralDiscountPopup = useCallback(() => {
    setReferralDiscountPopupShown(true);
  }, []);

  const value = {
    impactEmailPopupShown,
    turnOffImpactEmailPopup,
    referralDiscountPopupShown,
    turnOffReferralDiscountPopup,
    currentReferralCode,
    setCurrentReferralCode,
    withValidReferralCode,
    setWithValidReferralCode,
    setSessionHomePageLogoLink,
    sessionHomePageLogoLink,
  };

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
};

export const useUserSessionContext = () => useContext(UserSessionContext);
