import React from 'react';
import DocumentMeta from 'react-document-meta';
import './MetaHeader.scss';
import Helmet from 'react-helmet';

export const MetaHeader = ({ meta }) => {
  const className = 'c-MetaHeader';
  return (
    <DocumentMeta {...meta}>
      {meta?.h1 && <h1 className={`${className}__title`}>{meta?.h1}</h1>}
      <Helmet>
        <meta property="og:image" content={meta?.ogImage} />
        <meta property="og:url" content={`https://nas.academy${meta?.url}`} />
      </Helmet>
    </DocumentMeta>
  );
};

export default MetaHeader;
