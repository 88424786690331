import { useEffect, useState } from 'react';
import { getGeolocation } from '../services/geolocationService';

export const GEO_LOCATION_KEY = 'user_geo_location';

export const setGeolocaltionFromSession = (data) => {
  sessionStorage.setItem(GEO_LOCATION_KEY, JSON.stringify(data));
};
export const getGeolocaltionFromSession = () => {
  try {
    const data = sessionStorage.getItem(GEO_LOCATION_KEY);
    return JSON.parse(data);
  } catch {
    return null;
  }
};

const useGeolocation = () => {
  const [location, setLocation] = useState(getGeolocaltionFromSession());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLocation = async () => {
    setLoading(true);
    const { error, data } = await getGeolocation();
    setLoading(false);
    if (error) {
      setError(error);
      return;
    }

    setLocation(data);
    setGeolocaltionFromSession(data);
  };

  useEffect(() => {
    if (!location) fetchLocation();
  }, [location]);

  return {
    location,
    error,
    loading,
  };
};

export default useGeolocation;
