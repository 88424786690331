import {
  ACTION_CREATE_BUNDLE,
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COURSE,
} from '../contexts/UnifiedCheckoutContext/constants';
import { getCheckoutRoute } from '../contexts/UnifiedCheckoutContext/helper';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Sample queryString = '&coupon=ABC&email=abc@gmail.com
 * Sample Response : {
 *  coupon: 'ABC',
 *  email: 'abc@gmail.com'
 * }
 */
export const convertQueryStringToJson = (queryString) => {
  if (!queryString) return {};
  const pairs = queryString.slice(1).split('&');

  const responseJson = pairs.reduce((acc, pair) => {
    const keyValPair = pair.split('=');
    const key = keyValPair[0];
    const val = decodeURIComponent(keyValPair[1] || '');
    acc[key] = val;

    return acc;
  }, {});

  return responseJson;
};

export const convertJsonToQueryString = (jsonObj) => {
  let queryString = '?';

  if (!jsonObj) return queryString;

  try {
    const jsonKeys = Object.keys(jsonObj);

    for (let i = 0; i < jsonKeys.length; i++) {
      const dataStr = convertDataString(jsonObj[jsonKeys[i]]);

      queryString += `&${jsonKeys[i]}=${dataStr}`;
    }

    return queryString;
  } catch {
    return '?';
  }

  function convertDataString(data) {
    if (Array.isArray(data)) {
      return data.join(',');
    }
    return data.toString();
  }
};

export const getCourseSignupRoute = (courseOfferingId, step = 'enroll') => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_COURSE,
    courseOfferingId,
    step,
  });
};

export const getBundleCheckoutRoute = ({
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  step = 'enroll',
  bundleCourses,
  autoCreateBundle,
}) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_BUNDLE,
    bundleSlug,
    bundleAction,
    step,
    bundleCourses,
    autoCreateBundle,
  });
};
