import config from '../utility/config';
import { getDataFromRes } from './helpers';
const { apiPaymentBasePath, backendPaymentAuthKey } = config; // UNCOMMENT IN FINAL VERSION

const validateResStatus = (res) => {
  if (res.status >= 500) {
    throw new Error(res.message || 'Internal Server Error');
  }

  if (res.status >= 400) {
    throw new Error(res.message || 'Not Found');
  }
};

export const getCourseBundlesList = async () => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/get-course-bundles`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await getDataFromRes(res);

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getBundleCalculatedPrice = async (bundleId, courseCodes) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/get-course-bundle-price?bundleId=${bundleId}&courseCodes=${courseCodes}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await getDataFromRes(res);

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const bundlePurchaseSignup = async (params) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/bundle-purchase`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey,
      },
      body: JSON.stringify(params),
    };
    const res = await fetch(apiPath, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const submitBundleReceiverData = async (params) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/gift-details`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    const res = await fetch(apiPath, options);
    validateResStatus(res);
    const data = await res.json();
    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const confirmBundleReceiverData = async (params) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/confirm-gift-details`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    const res = await fetch(apiPath, options);
    validateResStatus(res);
    const data = await res.json();
    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};
