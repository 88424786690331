import { useEffect, useState } from 'react';
import { trackAddCourseToCart } from '../../utility/analytics';
import useStudentLogin from '../useStudentLogin';
import { useAuthContext } from '../../contexts/AuthContext';
import useInitCheckoutWithEmail from './useInitCheckoutWithEmail';
import {
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COURSE,
} from '../../contexts/UnifiedCheckoutContext/constants';

// TODO - Maybe remove from here and use the ones in useInitCheckoutWithEmail ?
export const SIGN_UP_EMAIL_SESSION_KEY = 'signupEmail';
export const SIGN_UP_ID_SESSION_KEY = 'signupId';
export const SIGN_UP_ACCESS_TOKEN_SESSION_KEY = 'accessToken';
export const SIGN_UP_USER_CREDITS_SESSION_KEY = 'signupUserCredits';

const useStartCheckout = ({ courseData, courseCode, autoCreateBundle }) => {
  const { user } = useAuthContext();
  const loggedInLearner = user?.learner;
  const [showPreCheckoutModal, setShowPreCheckoutModal] = useState(false);

  const [checkoutType, setCheckoutType] = useState('');
  const [selectedCourseOfferingId, setSelectedCourseOfferingId] = useState(
    null
  );
  const [bundleData, setBundleData] = useState(null);

  const {
    signupEmail,
    setSignupEmail,
    signupWithEmailErr,
    setSignupWithEmailErr,
    isSigningUpWithEmail,

    initCheckoutWithEmail,
  } = useInitCheckoutWithEmail();

  const [promptError, setPromptError] = useState('');

  const {
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
  } = useStudentLogin();

  const resetPromptError = () => {
    setPromptError('');
    setSignupWithEmailErr('');
    setSignupEmail('');
  };

  // For course purchase Join Click
  const onJoinClick = async (courseOfferingId) => {
    // Show Pre Checkout Modal
    setSelectedCourseOfferingId(courseOfferingId);
    setCheckoutType(CHECKOUT_TYPE_COURSE);

    const email = loggedInLearner?.email;
    if (email) {
      // if user is logged in. Initiate checkout with logged in user email.
      await initCheckoutWithEmail({
        checkoutType: CHECKOUT_TYPE_COURSE,
        email,
        courseOfferingId,
      });
    } else {
      setShowPreCheckoutModal(true);
    }

    // Track Analytics
    if (!courseData) return;
    const { currency, price } = courseData;
    trackAddCourseToCart({
      courseCode,
      courseOfferingId,
      currencyCode: currency,
      price,
    });
  };

  const onCheckoutBundleClick = async ({
    bundleId,
    bundleSlug,
    bundleCourses,
    isGiftPurchase,
    autoCreateBundle,
  }) => {
    // Show Pre Checkout Modal
    setShowPreCheckoutModal(true);
    const bundleDataParam = {
      bundleId,
      bundleSlug,
      bundleCourses,
      isGiftPurchase,
    };
    setBundleData(bundleDataParam);
    setCheckoutType(CHECKOUT_TYPE_BUNDLE);

    const email = loggedInLearner?.email;
    if (email) {
      // if user is logged in. Initiate checkout with logged in user email.
      await initCheckoutWithEmail({
        checkoutType,
        email,
        bundleData: bundleDataParam,
        bundleCourses,
        autoCreateBundle,
      });
    }
  };

  const checkoutWithEmail = (email) => {
    let checkoutWithEmailParams = {
      checkoutType,
      email,
      autoCreateBundle,
    };

    if (checkoutType === CHECKOUT_TYPE_COURSE) {
      checkoutWithEmailParams.courseOfferingId = selectedCourseOfferingId;
    }
    if (checkoutType === CHECKOUT_TYPE_BUNDLE) {
      checkoutWithEmailParams.bundleData = bundleData;
      checkoutWithEmailParams.bundleCourses = bundleData?.bundleCourses;
    }

    initCheckoutWithEmail(checkoutWithEmailParams);
  };

  const handleCheckoutWithEmail = (e) => {
    e.preventDefault();
    checkoutWithEmail(signupEmail);
  };

  const onSignupEmailChange = (value) => {
    setSignupEmail(value);
  };

  // If pre checkout modal is showing and user logs in,
  // run checkoutWithEmail function to initiate checkout with logged in user.
  useEffect(() => {
    const email = user?.learner?.email;
    if (email && showPreCheckoutModal) {
      checkoutWithEmail(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourseOfferingId, user]);

  // If error occurred during signup, close the modal and show prompt with error
  useEffect(() => {
    const loggedInLearner = user?.learner;
    const error = signupWithEmailErr || '';
    if (loggedInLearner && error) {
      setShowPreCheckoutModal(false);
      setPromptError(`${error}. Please choose another course.`);
    }
  }, [signupWithEmailErr, user]);

  return {
    // modal status
    showPreCheckoutModal,
    setShowPreCheckoutModal,

    // cta
    onJoinClick, // for course checkout
    onCheckoutBundleClick, // for bundle checkout

    // Login Form
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,

    // Signup w/ Email Form
    onSignupEmailChange,
    handleCheckoutWithEmail,
    signupWithEmailErr,
    signupEmail,
    isSigningUpWithEmail,

    // error prompt data
    promptError,
    resetPromptError,
  };
};

export default useStartCheckout;
