import { STATIC_ASSET_BASE_URL } from '../utility/constants';

export const STORYTELLING_COURSE_CODE = 'NS';
export const STORYTELLING_ONDEMAND_COURSE_CODE = 'NSPR';
export const PHOTOGRAPHY_COURSE_CODE = 'NP';
export const FACEBOOK_ONDEMAND_COURSE_CODE = 'NLPR';
export const CREATOR_MASTER_COURSE_CODE = 'NG';
export const DEAR_ALYNE_COURSE_CODE = 'DAPR';
export const CONTENT_CREATION_CRASH_COURSE = 'DA';
export const DEAR_ALYNE_ONDEMAND_COURSE_CODE = 'DAPR';
export const MONEY_COURSE_CODE = 'DAM';
export const THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE = 'TUC';
export const THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE = 'TUCPR';
export const TABOO_TOPICS_COURSE_CODE = 'DAT';
export const UNLOCKING_TIKTOK_WITH_MING_CODE = 'MTIK';
export const UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE = 'MTIKPR';
export const COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE = 'MCOM';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE = 'MCOM';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING = 'MCOMS';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING = 'MCOMA';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING = 'MCOME';
export const DEFAULT_TEMPLATE_ID = 1;
export const COMEDY_SKITS_LOW_COST_COURSE_CODE = 'MCOM2';
export const VFX_COURSE_CODE = 'VFX';
export const COURSE_LIVE_TYPE = 'live';
export const COURSE_PRERECORDED_TYPE = 'preRecorded';
export const CRYPTO_CREATOR_MASTER_COURSE = 'CCMC'; // TODO temp
export const CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE = 'MKPR';

// TODO add other necessary data for CCMC

export const COURSE_TYPE_LABEL = {
  [COURSE_LIVE_TYPE]: 'live',
  [COURSE_PRERECORDED_TYPE]: 'On-demand',
};

export const RECOMMEND_ENGINE_PAGE_TITLE = "Know what's best for you";

export const courseLinks = {
  [STORYTELLING_COURSE_CODE]: '/nasdaily',
  [STORYTELLING_ONDEMAND_COURSE_CODE]: '/nasdaily',
  [FACEBOOK_ONDEMAND_COURSE_CODE]: '/course/facebook',
  [PHOTOGRAPHY_COURSE_CODE]: '/course/photography',
  [CREATOR_MASTER_COURSE_CODE]: '/course/creator',
  [CRYPTO_CREATOR_MASTER_COURSE]: '/metapurse',
  [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]: '/cryptocreator',
  [DEAR_ALYNE_ONDEMAND_COURSE_CODE]: '/dearalyne',
  [MONEY_COURSE_CODE]: '/dearalyne/money',
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]: '/projectnightfall',
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]: '/projectnightfall',
  [TABOO_TOPICS_COURSE_CODE]: '/course/sensitive-topics',
  [UNLOCKING_TIKTOK_WITH_MING_CODE]: '/ming',
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]:
    '/ming-wei/unlocking-tiktok-with-ming',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE]: '/smilesquad',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING]: '/smilesquad/advanced',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING]: '/smilesquad/advanced',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING]: '/smilesquad/advanced',
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: '/smilesquad/comedy',
  [VFX_COURSE_CODE]: '/course/advanced',
};

const markianComedyCourseData = {
  title: 'Ultimate Viral Video Course',
  duration: '4',
  recommendedtext: '',
  sessions: ['Scripting', 'Editing', 'Shooting'],
  proSessions: [],
  classTime: '4 hours',
  videoTime: 'Make 1 video',
  instructorCount: 2,
  studentCount: 100,
  price: '99',
  priceSubtext: 'for the whole course.',
  discountText: [
    { text: 'Get up to 20% OFF', isHighlighted: true },
    { text: '  when enrolling with 2 or more friends.' },
  ],
  currency: 'USD',
  benefits: [
    '4 hour LIVE class with Markian & Friends.',
    'Learn how to Start, Earn & Grow on Youtube.',
    'Hands on Guidance.',
    'Get your Certificate of Accomplishment.',
  ],
  courseCode: ULTIMATE_VIRAL_VIDEO_COURSE_CODE,
  courseImgData: {
    alt: 'Ultimate Viral Video Course',
    mobileImgData: {
      src: `${STATIC_ASSET_BASE_URL}/na-website/CourseImages/jpg/markianCourseIconV2-225x240.jpg`,
      webpSrc: `${STATIC_ASSET_BASE_URL}/na-website/CourseImages/webp/markianCourseIconV2-225x240.webp`,
    },
  },
  courseImg: `${STATIC_ASSET_BASE_URL}/na-website/png/trial-benefit-1.png`,
  sectionTitle: 'Join my class now!',
  checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/MarkianComedyCourse/png/checklist.png`,
};

const markianComedyLowCostCourseData = {
  ...markianComedyCourseData,
  price: '49',
  discountPrice: '49',
  courseCode: COMEDY_SKITS_LOW_COST_COURSE_CODE,
};

const sensitiveTopicsCourseData = {
  price: '99',
  priceSubtext: 'for the whole course.',
  discountPrice: null,
  beforeDiscountPrice: null,
  discountText: 'Get up to 20% OFF when enrolling with 2 or more friends.',
  currency: 'USD',
  benefits: [
    '4 hours of live learning with Alyne.',
    'Understand the Content Creation Process.',
    'Learn the anatomy of a successful video.',
    'Access to Alumni Community.',
    'Certificate of Completion.',
  ],
  title: 'Sensitive Topics with Dear Alyne',
  courseCode: TABOO_TOPICS_COURSE_CODE,
  courseImgData: {
    alt: 'Dear Alyne Image',
    mobileImgData: {
      src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeDA.png',
    },
  },
  courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
  checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/svg/pinktick.svg`,
  sectionTitle: 'Book your seat now!',
};

export const coursesData = {
  [STORYTELLING_COURSE_CODE]: {
    title: 'Art of Storytelling',
    duration: '1',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing'],
    proSessions: [],
    classTime: '4 hours',
    videoTime: 'Observe Creators',
    instructorCount: 5,
    studentCount: 200,
    price: '49',
    discountPrice: '79',
    beforeDiscountPrice: '79',
    priceSubtext: 'for the whole course.',
    currency: 'USD',
    benefits: [
      'Live scripting exercises with Nas Daily team.',
      'A complete understanding of how to tell stories for the digital world.',
      'Tons of offline resources to help you grow.',
      'Online Certificate of Participation.',
    ],
    courseCode: 'NS',
    courseImgData: {
      alt: 'Art of Storytelling',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/Payment/png/AoSCourseImg.png',
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/Payment/webp/AoSCourseImg.webp',
      },
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/thumbAoS.png',
    pdfLink:
      STATIC_ASSET_BASE_URL + '/curriculum/Art_of_Storytelling_Curriculum.pdf',
  },
  [PHOTOGRAPHY_COURSE_CODE]: {
    title: 'Photography Mastercourse',
    duration: '8',
    classTime: '20 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '399',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' },
    ],
    currency: 'USD',
    benefits: [
      '8 fun sessions in 4 weeks.',
      '20 Hours of Teaching.',
      'How to Master Photography.',
      'How to take the perfect product picture.',
      'How to monetize your photography skills.',
    ],
    courseCode: 'NL',
    courseImgData: {
      alt: 'Photography Course',
      mobileImgData: {
        src:
          STATIC_ASSET_BASE_URL + '/na-website/Photography/jpg/courseImage.jpg',
      },
    },
    courseImgDataWithoutText: {
      alt: 'Photography Course',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/photographyCourseImageWithoutText.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/photographyCourseImageWithoutText.jpg',
        meta: {
          widht: 393,
          height: 419,
        },
      },
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
  },
  [CREATOR_MASTER_COURSE_CODE]: {
    title: 'Creator Mastercourse',
    duration: '8',
    recommendedtext: '',
    sessions: ['Scripting', 'Shooting', 'Editing', 'Publishing'],
    proSessions: ['Pro Scripting', 'Pro Shooting', 'Pro Editing'],
    classTime: '20 hours',
    videoTime: 'Make 3 videos',
    instructorCount: 2,
    studentCount: 20,
    price: '499',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' },
    ],
    currency: 'USD',
    benefits: [
      '8 live sessions with 20 hrs class time',
      'Create 3 videos with your own story.',
      'Library of copyright free songs & sound effects.',
      'Certificate of Accomplishment from Nas Academy',
    ],
    courseCode: 'NG',
    courseImgData: {
      alt: 'Go All In',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/Payment/png/CMCCourseImg.png',
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/Payment/webp/CMCCourseImg.webp',
      },
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/thumbMasterCourse.png',
    pdfLink:
      STATIC_ASSET_BASE_URL + '/curriculum/Creator_Mastercourse_Curriculum.pdf',
  },
  [DEAR_ALYNE_COURSE_CODE]: {
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: 'Get up to 20% OFF when enrolling with 2 or more friends.',
    currency: 'USD',
    benefits: [
      '4 hours of live learning with Alyne.',
      'Understand the Content Creation Process.',
      'Learn the anatomy of a successful video.',
      'Access to Alumni Community.',
    ],
    title: 'Content Creation Crash Course',
    courseCode: DEAR_ALYNE_COURSE_CODE,
    courseImgData: {
      alt: 'Dear Alyne Image',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeDA.png',
      },
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
  },
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]: {
    title: 'Unstoppable Confidence',
    courseCode: THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE,
    courseImgData: {
      alt: 'The Unstoppable Confidence',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.png',
        meta: {
          width: 656,
          height: 640,
        },
      },
    },
  },
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]: {
    title: 'Unstoppable Confidence (On-Demand)',
  },
  [FACEBOOK_ONDEMAND_COURSE_CODE]: {
    title: 'How To Crush It On Facebook (On-Demand)',
  },
  [UNLOCKING_TIKTOK_WITH_MING_CODE]: {
    title: 'How To Crush It On Tiktok with Ming',
    duration: '4',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing', 'Shooting'],
    proSessions: [],
    classTime: '10 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' },
    ],
    currency: 'USD',
    benefits: [
      '4 hour LIVE class with MING & Friends.',
      'Learn how to Start, Earn & Grow on TikTok.',
      'Hands on Guidance.',
      'Get your Certificate of Accomplishment.',
    ],
    courseCode: 'MTIK',
    courseImgData: {
      alt: 'Tiktok Course',
      mobileImgData: {
        src: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/mtikCourseImg.png`,
        webpSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/webp/mtikCourseImg.webp`,
      },
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
    sectionTitle: 'Join my class now!',
    checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/checkList.png`,
  },
  [COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE]: markianComedyCourseData,
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: markianComedyLowCostCourseData,
  [VFX_COURSE_CODE]: {
    title: 'Advanced Editing Effects',
    courseCode: 'NG',
    courseImgData: {
      alt: 'Advenced Editing Effects Image',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeVFX.png',
      },
    },
  },
  [TABOO_TOPICS_COURSE_CODE]: sensitiveTopicsCourseData,
  [STORYTELLING_ONDEMAND_COURSE_CODE]: {
    title: 'Art of Storytelling(On-Demand)',
  },
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]: {
    title: 'How To Crush It On Tiktok with Ming (On-Demand)',
  },
  [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]: {
    title: 'The Future of Crypto',
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING]: {
    title: 'Acting Workshop',
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING]: {
    title: 'Scripting Workshop',
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING]: {
    title: 'Editing Workshop',
  },
  [MONEY_COURSE_CODE]: {
    title: 'Let\'s Talk Money',
  },
  [CONTENT_CREATION_CRASH_COURSE]: {
    title: 'Content Creation Crash Course',
  },
};

export const courseList = [
  coursesData[STORYTELLING_COURSE_CODE],
  coursesData[CREATOR_MASTER_COURSE_CODE],
];

export const oneDayCoursesCardData = [
  {
    title: [
      { text: 'Art of', color: 'white' },
      { text: 'Storytelling', color: 'yellow' },
    ],
    hasGradient: true,
    courseCode: STORYTELLING_COURSE_CODE,
    link: courseLinks[STORYTELLING_COURSE_CODE],
    imgData: {
      alt: 'Art of Stroytelling',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/CourseImages/aosCardImg.webp',
        src: STATIC_ASSET_BASE_URL + '/na-website/CourseImages/aosCardImg.png',
        meta: {
          width: 600,
          height: 640,
        },
      },
    },
  },
  {
    title: [{ text: 'Dear Alyne', color: 'yellow' }],
    hasGradient: true,
    subtitle: 'Content Creation Crash Course',
    courseCode: DEAR_ALYNE_COURSE_CODE,
    link: courseLinks[DEAR_ALYNE_COURSE_CODE],
    imgData: coursesData[DEAR_ALYNE_COURSE_CODE].courseImgData,
  },
  {
    title: [
      { text: 'Unstoppable', color: 'white' },
      { text: 'Confidence', color: 'yellow' },
    ],
    hasGradient: true,
    courseCode: THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE,
    link: courseLinks[THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE],
    imgData: coursesData[THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE].courseImgData,
  },
  {
    title: [],
    hasGradient: false,
    courseCode: UNLOCKING_TIKTOK_WITH_MING_CODE,
    link: courseLinks[UNLOCKING_TIKTOK_WITH_MING_CODE],
    image: STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/courseCard.png',
    imgData: {
      alt: 'Unlocking Titok with Ming',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/webp/courseCard.webp',
        src:
          STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/courseCard.png',
        meta: {
          widht: 393,
          height: 419,
        },
      },
    },
  },
];

export const masterCoursesCardData = [
  {
    title: [
      { text: 'Creator', color: 'yellow' },
      { text: 'Mastercourse', color: 'white' },
    ],
    courseCode: CREATOR_MASTER_COURSE_CODE,
    link: courseLinks[CREATOR_MASTER_COURSE_CODE],
    image:
      STATIC_ASSET_BASE_URL +
      '/na-website/NewHome/png/homeCreatorMastercourse.png',
    imgData: {
      alt: 'Creator Mastercourse',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/CourseImages/cmcCardImg.webp',
        src: STATIC_ASSET_BASE_URL + '/na-website/CourseImages/cmcCardImg.png',
        meta: {
          width: 600,
          height: 640,
        },
      },
    },
  },
  {
    title: [
      { text: 'Photography', color: 'yellow' },
      { text: 'Mastercourse', color: 'white' },
    ],
    courseCode: PHOTOGRAPHY_COURSE_CODE,
    link: courseLinks[PHOTOGRAPHY_COURSE_CODE],
    imgData: coursesData[PHOTOGRAPHY_COURSE_CODE].courseImgDataWithoutText,
  },
  {
    title: [
      { text: 'Advanced', color: 'white' },
      { text: 'Editing Effects', color: 'yellow' },
    ],
    courseCode: VFX_COURSE_CODE,
    link: courseLinks[VFX_COURSE_CODE],
    image: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeVFX.png',
    imgData: {
      alt: 'Advanced Editing Effects',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL + '/na-website/CourseImages/vfxCardImg.webp',
        src: STATIC_ASSET_BASE_URL + '/na-website/CourseImages/vfxCardImg.png',
        meta: {
          width: 600,
          height: 640,
        },
      },
    },
  },
];

const markianEnrollmentCoverImage =
  STATIC_ASSET_BASE_URL +
  '/na-website/MarkianComedyCourse/png/markianPaymentBanner_656x340.png';

export const enrollmentCoverImage = {
  [CREATOR_MASTER_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pCMastercourse.png',
  [STORYTELLING_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pAoS.png',
  [STORYTELLING_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pAoS.png',
  [DEAR_ALYNE_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/DearAlyne/png/pCCCwDA.png',
  [PHOTOGRAPHY_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/Payment/png/pPhotoMC.png',
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/CourseImages/tuc.png',
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/CourseImages/tuc.png',
  [UNLOCKING_TIKTOK_WITH_MING_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png',
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png',
  // [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]:
  //   STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png', // TODO not needed anymore, but leaving it just for reference
  [COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE]: markianEnrollmentCoverImage,
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: markianEnrollmentCoverImage,
  [TABOO_TOPICS_COURSE_CODE]:
    STATIC_ASSET_BASE_URL +
    '/na-website/CourseImages/sensitive-topics/sensitiveTopicsPaymentBanner1_656x340.jpeg',
};

export const checkmarkIconDefaultSrc =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/greentick.svg';

export const pinkCheckmarkIconSrc =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/pinktick.svg';
