import config from './config';

export const CONTACT_EMAIL = 'info@thenasacademy.com';
export const STATIC_ASSET_BASE_URL = config.imagesBasePath;
export const NA_PRIMARY_COLOR = '#fbc91b';
export const DOCUMENT_TITLE_TEMPLATE = 'Nas Academy - We create Creators';
export const NA_CONTACT_EMAIL = 'hello@nas.academy';
export const CONTACT_MAIL_TO = `mailto:${CONTACT_EMAIL}`;
export const IMPACT_URL = '/impact';
export const NAS_ACADEMY_FB_PAGE = 'https://www.facebook.com/nasacademy';
export const NAS_ACADEMY_IG_PAGE = 'https://www.instagram.com/nasacademy';
export const REFERRAL_CODE_API = '/code';
export const REFERRAL_CODE_VALIDATION_API = `${config.loginPath}/api/v1/referral-code?code`;

export const calendarVendorOptions = [
  { google: 'Google' },
  { apple: 'iCal' },
  { outlook: 'Outlook' },
  { outlookcom: 'Outlook.com' },
  { yahoo: 'Yahoo!' },
];
