export const pageMetaMap = {
  '/': {
    title: 'Nas Academy - Learn From Creators',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Nas Academy - Learn From Creators',
    ogDescription: 'Nas Academy - Learn From Creator',
    url: '/',
  },
  '/projectnightfall': {
    title: 'Unstoppable Confidence with Project Nightfall',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/confidenceCardImg.png',
    h1: 'Unstoppable Confidence with Project Nightfall',
    ogDescription: 'Unstoppable Confidence with Project Nightfall',
    url: '/projectnightfall',
  },
  '/nasdaily': {
    title: 'Art of Storytelling with Nas Daily',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/aosCardImg.png',
    h1: 'Art of Storytelling with Nas Daily',
    ogDescription: 'Art of Storytelling with Nas Daily',
    url: '/nasdaily',
  },
  '/ming': {
    title: 'How To Crush It on Tik Tok with Ming',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/MingTikTok/png/mtikCourseImg.png',
    h1: 'How To Crush It on Tik Tok with Ming',
    ogDescription: 'How To Crush It on Tik Tok with Ming',
    url: '/ming',
  },
  '/course/facebook': {
    title: 'How To Crush It On Facebook with Nas Daily',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/aosCardImg.png',
    h1: 'How To Crush It On Facebook with Nas Daily',
    ogDescription: 'How To Crush It On Facebook with Nas Daily',
    url: '/course/facebook',
  },
  '/cryptocreator': {
    title: 'Crypto Creator Mastercourse',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Crypto Creator Mastercourse',
    ogDescription: 'Crypto Creator Mastercourse',
    url: '/cryptocreator',
  },
  '/course/creator': {
    title: 'Creator MasterCourse',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/Payment/png/CMCCourseImg.png',
    h1: 'Creator MasterCourse',
    ogDescription: 'Creator MasterCourse',
    url: '/course/facebook',
  },
  '/course/advanced': {
    title: 'Advanced Editing Effect',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/vfxCardImg.png',
    h1: 'Advanced Editing Effect',
    ogDescription: 'Advanced Editing Effect',
    url: '/course/advanced',
  },
  '/dearalyne': {
    title: 'Content Creation with Dear Alyne',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/NewHome/png/homeDA.png',
    h1: 'Content Creation with Dear Alyne',
    ogDescription: 'Content Creation with Dear Alyne',
    url: '/dearalyne',
  },
  '/dearalyne-od': {
    title: 'Content Creation with Dear Alyne',
    ogImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/na-website/NewHome/png/homeDA.png',
    h1: 'Content Creation with Dear Alyne',
    ogDescription: 'Content Creation with Dear Alyne',
    url: '/dearalyne-od',
  },
  '/course/sensitive-topics': {
    title: 'Sensitive Topics with Dear Alyne',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/sensitive-topics/sensitiveTopicsPaymentBanner1_656x340.jpeg',
    h1: 'Sensitive Topics with Dear Alyne',
    ogDescription: 'Sensitive Topics with Dear Alyne',
    url: '/course/sensitive-topics',
  },
  '/smilesquad': {
    title: 'Ultimate Viral Video Course by Smile Squad',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/CourseImages/jpg/markianCourseIconV2-225x240.jpg',
    h1: 'Ultimate Viral Video Course by Smile Squad',
    ogDescription: 'Ultimate Viral Video Course by Smile Squad',
    url: '/smilesquad',
  },
  '/#bundle': {
    title: 'Course Bundles by Nas Academy',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Course Bundles by Nas Academy',
    ogDescription: 'Course Bundles by Nas Academy',
    url: '/#bundle',
  },
  '/trial': {
    title: 'Free Trial',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    ogDescription: 'Free Trial',
    url: '/trial',
  },
  '/course/business': {
    title: 'Nas Academy for Business',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    ogDescription: 'Nas Academy for Business',
    url: '/course/business',
  },
  '/best-for-me': {
    title: 'Which course is for me?',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/forwebshare.jpg',
    h1: 'Which course is for me?',
    ogDescription: 'Which course is for me?',
    url: '/best-for-me',
  },
  '/about-us': {
    title: 'About Us',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'About Us',
    ogDescription: 'About Us',
    url: '/about-us',
  },
  '/enrolment-terms': {
    title: 'Enrolment Terms and Conditions',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Enrolment Terms and Conditions',
    ogDescription: 'Enrolment Terms and Conditions',
    url: '/enrolment-terms',
  },
  '/privacy': {
    title: 'Privacy Policy',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Privacy Policy',
    ogDescription: 'Privacy Policy',
    url: '/privacy',
  },
  '/faq': {
    title: 'Frequently Asked Questions',
    ogImage:
      'https://d2oi1rqwb0pj00.cloudfront.net/na-website/jpg/logo_ogg.jpg',
    h1: 'Frequently Asked Questions',
    ogDescription: 'Frequently Asked Questions',
    url: '/faq',
  },
};

export default pageMetaMap;
