export const [CHECKOUT_TYPE_COURSE, CHECKOUT_TYPE_BUNDLE] = [
  'course',
  'bundle',
];

export const [ACTION_CREATE_BUNDLE, ACTION_CHECKOUT_BUNDLE] = [
  'create',
  'checkout',
];

// checkout payment step views
export const [
  DEFAULT_PAYMENT_VIEW,
  OTHER_PAYMENT_OPTIONS_VIEW,
  DISCOUNT_CODE_VIEW,
] = ['default', 'others', 'discount'];

// payment methods
export const DEFAULT_PAYMENT_METHOD = 'card';

// Payment types
export const [FULL_PAYMENT, DEPOSIT_PAYMENT] = ['full', 'deposit'];

// Discount types
export const [DISCOUNT_TYPE_PERCENT_OFF, DISCOUNT_TYPE_AMOUNT_OFF] = [
  'percent_off',
  'amount_off',
];

// Gift card coupon code type
export const GIFT_CODE_TYPE = 'giftCard';
