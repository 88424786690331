import config from '../utility/config';
import { getAxiosError } from './helpers';
import protectedAxiosRequest from './helpers/protectedAxiosRequest';
const { loginPath } = config;

export const userLoginService = async (email, password) => {
  try {
    const path = loginPath + '/api/v1/log-in';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginPath}/api/v1/get-token`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return { data, error: '' };
  } catch (e) {
    return { error: e.message };
  }
};

export const getUserProfile = async () => {
  try {
    return await protectedAxiosRequest.get(`${loginPath}/api/v1/user-profile`);
  } catch (e) {
    return getAxiosError(e);
  }
};
