import authorizedRequest from './helpers/authorizedRequest';
import config from '../utility/config';
import { getDataFromRes } from './helpers';

const {
  apiPaymentBasePath,
  apiNewPaymentBasePath,
  backendPaymentAuthKey,
} = config;

export const signupService = async (
  courseCode,
  courseType,
  agreeTermsAndCondition,
  timezone,
  basicInfo,
  signupId = null
) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/sign-up/';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey,
      },
      body: JSON.stringify({
        courseCode,
        courseType,
        agreeTermsAndCondition,
        timezone,
        basicInfo: basicInfo.map((basicUserInfo) => {
          return { studentInfo: basicUserInfo };
        }),
        signupId,
      }),
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

// export const sendLeadEmailService = async ({
export const initiateCheckoutWithEmail = async ({
  courseCode,
  courseType,
  timezone,
  basicInfo,
}) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/email-sign-up/';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey,
      },
      body: JSON.stringify({
        courseCode,
        courseType,
        timezone,
        basicInfo,
      }),
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data };
  } catch (e) {
    return { error: e.message };
  }
};

export const applyDiscountCodeService = async (couponCode) => {
  const path = apiPaymentBasePath + '/api/v1/sign-up-apply-discount/';
  const body = JSON.stringify({ couponCode });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  };

  const response = await authorizedRequest(path, options);
  return response;
};

/**
 * Payment service for enrollment
 * @param {object} courseCode The course code
 * - currency: string ("USD")
 * - paymentType: "full" | "deposit"
 * - discount? bool
 * - credit? bool
 * @return {boolean} True if course code is for teen course, and False otherwise.
 */
export const signUpPaymentService = async (params) => {
  const path = apiPaymentBasePath + '/api/v1/sign-up-payment/';
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };

  const response = await authorizedRequest(path, options);
  return response;
};

export const getRedirectLinkOnPaymentSuccess = async (bookingId) => {
  const path =
    apiPaymentBasePath +
    `/api/v1/redirect-link?bookingId=${bookingId}&courseType=general`;
  const options = { method: 'GET' };

  const response = await authorizedRequest(path, options);
  return response;
};

export const getPaymentMethodsByCountry = async (countryId) => {
  try {
    const path = `${apiNewPaymentBasePath}/api/v1/payment-methods?countryId=${countryId}`;

    const options = { method: 'GET' };
    const res = await fetch(path, options);
    const data = await getDataFromRes(res);

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data };
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * @param {*} params.paymentMethod
 * @param {*} params.currency
 * @param {*} params.paymentType
 */
export const signUpRapydCheckout = async (params) => {
  const path = apiPaymentBasePath + '/api/v1/sign-up-checkout/';

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };

  const response = await authorizedRequest(path, options);
  return response;
};
