import React from 'react';
import { useHistory } from 'react-router-dom';

const MarkianRedirect = () => {
  const history = useHistory();

  const redirectUrl = `/smilesquad/comedy-skits${window.location.search}`;

  history.push(redirectUrl);

  return (
    <div>
      <span>Redirecting To Smilesquad Page...</span>
    </div>
  );
};

export default MarkianRedirect;
