const config = {
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  wisentApiKey: process.env.REACT_APP_WISENET_API_KEY,
  apiPaymentBasePath: process.env.REACT_APP_BACKEND_PAYMENT_URL,
  apiNewPaymentBasePath: process.env.REACT_APP_NEW_BACKEND_PAYMENT_URL,
  apiRapydPaymentPath: process.env.REACT_APP_BACKEND_RAPYD_PAYMENT_URL,
  apiStaticDataPath: process.env.REACT_APP_BACKEND_STATIC_DATA_SEVER,
  loginPath: process.env.REACT_APP_BACKEND_PROTECTED_ROUTE,
  stripeApiKey: process.env.REACT_APP_STRIPE_KEY,
  backendPaymentAuthKey: process.env.REACT_APP_BACKEND_PAYMENT_AUTH,
  nasdailyFbPixelId: process.env.REACT_APP_FB_PIXEL_ID,
  academyFbPixelId: process.env.REACT_APP_FB_PIXEL_ID_ACADEMY,
  gtmId: process.env.REACT_APP_GTM,
  imagesBasePath: process.env.REACT_APP_CLOUDFRONT_URL,
  oneDayCourseTemplateId: process.env.REACT_APP_ONE_DAY_COURSE_TEMPLATE_ID,
  rapydToolkitSrc: process.env.REACT_APP_RAPYD_TOOLKIT_SRC,
  mingGOExperimentID: process.env.REACT_APP_MING_GO_EXPERIMENT_ID,
  markianGOExpId: process.env.REACT_APP_MARKIAN_GO_EXPERIMENT_ID,
  sensitiveGOExpId: process.env.REACT_APP_SENSITIVE_GO_EXPERIMENT_ID,
  storytellingGOExpId: process.env.REACT_APP_STORYTELLING_GO_EXPERIMENT_ID,
  daccGOExpId: process.env.REACT_APP_DACC_GO_EXPERIMENT_ID,
  paymentGOExpId: process.env.REACT_APP_PAYMENT_GO_EXPERIMENT_ID,
  enrollmentPageGOExpId: process.env.REACT_APP_ENROLLMENT_GO_EXPERIMENT_ID,
  learningPortalPath: process.env.REACT_APP_LEARNER_PORTAL_PATH,
  creatorCourseGOId: process.env.REACT_APP_CREATOR_GO_EXPERIMENT_ID,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  homePageGOExperimentId: process.env.REACT_APP_HOMEPAGE_GO_EXPERIMENT_ID,
};

export default config;
