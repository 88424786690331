import config from '../utility/config';

const { apiPaymentBasePath } = config;

export const getGeolocation = async () => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/get-country-data`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info || data?.message || data?.error || 'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};
