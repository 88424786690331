import TagManager from 'react-gtm-module';
import { getGeolocaltionFromSession } from '../hooks/useGeolocation';
import config from '../utility/config';

export const initiateGTM = () => {
  const tagManagerArgs = {
    gtmId: config?.gtmId,
  };

  TagManager.initialize(tagManagerArgs);
};

//Optimize
export const initialOptimizeExperimentVariant = '0';

export const callOptimizeEvent = async function (experimentId, callback) {
  let intervalId = setInterval(() => {
    if (window.google_optimize !== undefined) {
      const variant = window.google_optimize.get(experimentId);
      if (!!variant) {
        callback(variant);
        clearInterval(intervalId);
      }
    }
  }, 100);
};

export const trackCourseImpression = ({
  courseCode,
  currencyCode = 'USD',
  price,
}) => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode,
      impressions: [
        {
          name: courseCode,
          id: courseCode,
          price,
        },
      ],
    },
  });
};

export const trackCourseDetailView = ({
  courseCode,
  currencyCode = 'USD',
  price,
}) => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode,
      detail: {
        products: [
          {
            name: courseCode,
            id: courseCode,
            price,
          },
        ],
      },
    },
  });
};

export const trackAddCourseToCart = ({
  courseCode,
  courseOfferingId,
  currencyCode = 'USD',
  price,
  quantity = 1,
}) => {
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode,
      add: {
        products: [
          {
            name: courseOfferingId,
            id: courseCode,
            price,
            category: courseCode,
            quantity,
          },
        ],
      },
    },
  });
};

export const trackCheckout = ({
  courseCode,
  courseOfferingId,
  price,
  coupon = '',
  step,
  option = '',
}) => {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step, option },
        products: [
          {
            name: courseOfferingId,
            id: courseCode,
            price,
            category: courseCode,
            coupon,
          },
        ],
      },
    },
  });
};

export const trackCheckoutOptions = ({ step, option }) => {
  window.dataLayer.push({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: { step, option },
      },
    },
  });
};

export const trackEnhancedPurchase = ({
  transactionId,
  courseName,
  courseCode,
  courseOfferingId,
  price,
  coupon = '',
  quantity = 1,
}) => {
  window.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
        },
        products: [
          {
            name: `${courseName} | ${courseOfferingId}`,
            id: courseCode,
            price,
            category: courseCode,
            coupon,
            quantity,
          },
        ],
      },
    },
  });
};

export const trackAddPaymentInfo = (data = {}) => {
  window.dataLayer.push({
    event: 'AddPaymentInfo',
    data,
  });
};

export const trackInitiateCheckout = () => {
  const geoLocation = getGeolocaltionFromSession();

  const infoObject = !!geoLocation
    ? {
        event: 'InitiateCheckout',
        ...geoLocation,
      }
    : {
        event: 'InitiateCheckout',
      };

  window.dataLayer.push(infoObject);
};

export const trackAddDiscountClick = () => {
  window.dataLayer.push({
    event: 'DiscountClick',
  });
};

export const trackSelectPaymentOptionClick = (data = {}) => {
  window.dataLayer.push({
    event: 'SelectPaymentOption',
    data,
  });
};

export const trackSelectStripeClick = () => {
  window.dataLayer.push({
    event: 'SelectPaymentOptionStripe',
    data: {
      category: 'stripe',
      action: 'click',
      label: 'stripe',
    },
  });
};

export const trackSelectRapydClick = () => {
  window.dataLayer.push({
    event: 'SelectPaymentOptionRapyd',
    data: {
      category: 'rapyd',
      action: 'click',
      label: 'rapyd',
    },
  });
};

export const trackSelectRapydOptionClick = (option) => {
  window.dataLayer.push({
    event: `SelectPaymentOptionRapyd-${option}`,
    data: {
      category: 'rapyd',
      action: 'click',
      label: option,
    },
  });
};

export const trackLead = (email) => {
  window.dataLayer.push({
    event: 'Lead',
    data: {
      email,
    },
  });
};

export const trackPurchase = (paymentTrackingOptions) => {
  window.dataLayer.push({
    event: 'Purchase',
    data: {
      courseOfferingId: paymentTrackingOptions.courseOfferingId || '',
      courseOfferingTitle: paymentTrackingOptions.courseOfferingTitle || '',
      paymentAmount: paymentTrackingOptions.value,
      currency: paymentTrackingOptions.currency ?? 'USD',
      pricePerItem: paymentTrackingOptions.pricePerItem,
      quantity: paymentTrackingOptions.quantity || 1,
      eventID: paymentTrackingOptions.event_id ?? '',
      contentType: paymentTrackingOptions.contentType || 'product',
    },
  });
};

export const trackMXSignup = (options) => {
  window.dataLayer.push({
    event: 'MXSignup',
    data: {
      email: options.email,
    },
  });
};

export const trackTrialClassSignup = () => {
  window.dataLayer.push({ event: 'TrialClassSignup' });
};

export const impactTagEvents = {
  SEE_MORE_BTN: 'Impact - See More Btn Clicked',
  COURSE_CARD_CLICK: 'Impact - Course Card Clicked',
  MODAL_POPUP: 'Impact - Modal Popped Up',
  MODAL_SUBMIT: 'Impact - Modal Submitted',
};

export const trackSeeMoreClassesImpact = () => {
  window.dataLayer.push({ event: impactTagEvents?.SEE_MORE_BTN });
};

export const trackCourseCardImpact = (courseName) => {
  window.dataLayer.push({
    event: impactTagEvents?.COURSE_CARD_CLICK,
    data: {
      courseName,
    },
  });
};

export const trackModalPopupImpact = () => {
  window.dataLayer.push({ event: impactTagEvents?.MODAL_POPUP });
};

export const trackModalSubmitImpact = () => {
  window.dataLayer.push({ event: impactTagEvents?.MODAL_SUBMIT });
};

export const trackWaitlistModalEvent = (event) => {
  window.dataLayer.push({ event });
};
