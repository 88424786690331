import { DOCUMENT_TITLE_TEMPLATE } from './constants';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToRef = (ref, negOffset = 0) =>
  window.scrollTo(0, ref?.current?.offsetTop - negOffset);

export const scrollToDom = (element, offset) =>
  window.scrollTo(0, element?.offsetTop - offset);

export const isInViewport = (ref) => {
  if (!ref.current) return false;

  const rect = ref.current.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isInViewportHeight = (ref) => {
  const rect = ref.current.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const updateDocumentTitle = (courseName) => {
  const prefix = courseName ? `${courseName} | ` : '';

  document.title = `${prefix}${DOCUMENT_TITLE_TEMPLATE}`;
};

export const minDesktopWidth = 1200;
export const isMobile = window.innerWidth < 470;
export const isMobileOrTablet = window.innerWidth < 1200;
export const isTablet = window.innerWidth >= 768;
export const isDesktop = window.innerWidth >= minDesktopWidth;
export const fbVideoDesktopHeight = 490;
export const fbVideoMobileHeight = 914;

export const checkIsDesktop = () => window.innerWidth >= minDesktopWidth;
