export const getDataFromRes = async (res) => {
  const data = await res.json();

  // Check Res Status for Error.
  if (res.status >= 500) {
    const msg = data?.message || 'Internal Server Error';
    throw new Error(msg);
  }

  if (res.status >= 400) {
    const msg = data?.info || data?.message || 'Not Found.';
    throw new Error(msg);
  }

  return data;
};

export const getAxiosError = (error) => ({
  error: error?.response?.data?.message || 'Something went wrong!',
});
