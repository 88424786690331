import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RedirectWithQuery = ({ from, to, ...rest }) => {
  return (
    <Route
      path={from}
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: to,
            search: props.location?.search || '',
          }}
        />
      )}
    />
  );
};

export default RedirectWithQuery;
